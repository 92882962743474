@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

body {
  background-color: black;
  overflow-x: hidden;
}

.hero-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FFB2E6;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background8.png');
  background-size: cover;
  background-position: center;
}

.hero-section h1 {
  font-size: 5rem;
  color: #F9C80E;
  font-weight: 900;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.hero-section h2 {
  font-size: 1.8rem;
  color: #FFFFE8;
  font-family: Roboto;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  margin-bottom: 5rem;
}

.hero-section h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FFFFE8;
  background-color: #00e8fc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-section h3:hover {
  background-color: #D972FF;
  color: #FFFFE8;
}

.hero-challenge h2 {
  color: #FFFFE8;
  margin-bottom: 1rem;
}

.hero-challenge span {
  font-size: 1.5rem;
  color: #FFFFE8;
  font-family: Roboto;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  margin-bottom: 5rem;
}

.user-name {
  font-size: 1rem;
}

.hero-cta-buttons {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.cta-button {
  background-color: #1E3A8A;
  border: none;
  border-radius: 30px;
  color: #EAB308;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 15px 30px;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  margin: 5px;
  flex: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out
}

.cta-button:hover {
  background-color: #D972FF;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

.explore-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.explore-text {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8447FF;
  margin-bottom: 5px;
}

.arrow-down {
  font-size: 24px;
  animation: bounce 2s infinite;
  color: #FFFFE8;
}

/*.second-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background14.png');
  background-size: cover;
  background-position: center;
}

.third-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FFB2E6;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background10.png');
  background-size: cover;
  background-position: center;
}

.fourth-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FFB2E6;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background11.png');
  background-size: cover;
  background-position: center;
}

.fifth-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #FFB2E6;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background12.png');
  background-size: cover;
  background-position: center;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
*/
.challenge-prompt {
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFFFE8;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.user-name {
  font-size: 1.2rem;
  font-weight: normal;
  color: #FFFFE8;
  margin-left: 5px;
}

.second-section h1 {
  font-size: 3rem;
  color: #FFFFE8;
  font-weight: bold;
  font-family: Roboto;
  margin-bottom: 1rem;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.second-section h2 {
  font-size: 1.5rem;
  font-family: Roboto;
  font-weight: normal;
  justify-content: center;
  text-align: center;
}

.second-section h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #FFFFE8;
  background-color: #00e8fc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-arrow-container {
  margin-top: 100px;
}

.poster-image-wrapper {
  width: 100vw;
  height: 62vh;
  position: relative;
  overflow: hidden;
}

.poster-image {
  background-size: cover;
  background-position: center;
  width: auto;
  height: 100%;
}

.featured-musician-container a.link {
  text-decoration: none;
  color: inherit;
}


.link {
  text-decoration: none;
}




@media only screen and (max-width: 767px) {

  body {
    padding-top: 32px;
  }

  .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFB2E6;
    text-align: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background8.png');
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
  }

  .hero-section h1 {
    font-size: 1.9rem;
  }

  .hero-section h2 {
    font-size: 1.2rem;
  }

  .hero-section h3 {
    font-size: 1rem;
  }

  .hero-section h2 {
    margin-bottom: 3rem;
  }

  .hero-cta-buttons {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0;
  }

  
.cta-button {
  font-size: 1rem;
  width: 50vw;
}
}