.challengeGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-gap: 2rem;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.challenge-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.challenges-bg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background14.png');
    background-size: cover;
    background-position: cover;
    min-height: 100vh;
    overflow: hidden;
}

.issuechallengebtn {
    margin-top: 100px;
    /* Adjust this value as needed */
    /* ... */
}

body {
    padding: 0 !important;
}


@media screen and (max-width: 600px) {

    .challengeGrid {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        padding: 1rem;
    }

    .issuechallengebtn {
        margin-top: 100px;
        /* Adjust this value as needed */
    }

    .challenges-bg {
        min-height: 64px;
        overflow: hidden;
    }
}