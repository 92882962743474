.user-profile-container {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
}


.user-profile {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  width: 100%;
  /* To make the card responsive */
  /*background-color: rgba(255, 255, 255, 0.9);*/
  border-radius: 10px;
  /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);*/
  margin-bottom: 2rem;
  /* Add this line */
}

.user-video-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}


.profile-avatar {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
  border: 3px solid #3f51b5;
}

.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.user-video-card {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin: 10px;
}

.user-name {
  margin-top: 1rem;
  text-align: center;
  color: #fff;
}

.user-bio {
  margin-top: 0.5rem;
  text-align: center;
  color: #fff
}

body::after {
  content: '';
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/background13.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Add this new class for menu container */
.menu-container {
  overflow-x: auto;
  white-space: nowrap;
}

@media only screen and (max-width: 767px) {}